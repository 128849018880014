import ice from './Illustrations/IllustartionIce.png';
import Aalto from './Illustrations/IllustrationAalto.png';
import Ant from './Illustrations/IllustrationAnt.png';
import House from './Illustrations/IllustrationHouse.png';
import Idli from './Illustrations/IllustrationIdli.png';
import Net from './Illustrations/IllustrationNet.png';
import Reading from './Illustrations/IllustrtionReading.png';
import MonstersInc from './Illustrations/MonstersInc.jpg';



const cardData = [
  {
    img: Reading,
    description:'Advocating some social distancing with my illustration',
   
 },
  {
    img: Net,
    description:'In the forest of life, the internet is ubiquitous!',
   
 },
 {
    img: Aalto,
    description:'Thank you Aalto for giving my the opportunity to live the teekkari life♥️ I already miss it!',
    
 },
   
  {
    img: MonstersInc,
    description:' Art here to remind you to stretch a bit inbetween your work-from-home sessions. Don\'t worry guys, this too shall pass :)',
    
 },
   {
    img: Idli,
    description:'Things I will be missing the next weeks since I cannot travel as planned this April: mom-made idli, vada sambar and all other circular ring-ular things!! ',
   
  },
  {
    img: House,
    description:'Isn\'t the concept of home abstract? Sometimes it becomes possible to call two places home!',
  },
  {
    img: ice,
    description:'This winter, I Iearnt how to ice skate I was super scared when I started but I was pretty determined to learn and so, I did B) The comment I got from everyone when I was learning was: \"don\'t overthink this, just do it\". As an adult it\'s so hard not to! This illustration represents my overthinking wrt ice-skating. Was so much fun and I had a hard time saying bye to the ice this winter.When was the last time you did something for the first time? ;)',
  },
  {
    img: Ant,
    description:'A favorite pastime of mine when I was home was to sit on the "mitta" (the slab next to the stove) and eat while talking to my mom. Ever so often there would be ants there and I used to clear the trails they made, confusing the ants that followed. Found it fascinating that doing that, confused them so much XD I miss home, I miss this and I truly miss the ants ( there are just no ants in the kitchen here in Stockholm which, I find bizzare XD). An important part of this story is that, my sitting on the "mitta" used to drive my thatha crazy. I miss him so so much! I miss fighting with him, I miss talking to him!',

  },

];
export default cardData;