import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import hsl from './HSLUsability/hsl.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';

// IMPORT IMAGES HERE
var image2 = require('./PortfolioUse.png');
var paasto = require('./Paasto.svg');
var elename = Array.from(document.querySelectorAll("h3")).map(node => node.innerHTML);

var Scroll  = require('react-scroll');

var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class Reach extends Component {
  constructor(props) {
    super(props)

    this.state = { terms: null }
  }

  componentWillMount() {
    fetch(hsl) // download the md file with HTTP GET
    .then((response) => response.text()) // get md text from the .md network response
    .then(this.mapImages) // replace the images
    .then((text) => {
      this.setState({ terms: text })
    })
  }

  mapImages(markdownText) {
    // const escapeRegex = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // create search regex
    // var regex = new RegExp( IMAGE_MAPPING.map(escapeRegex).join("|"), "g");
    // replace with image paths
    return markdownText.replace(/!\[.*\]\((?!http)(.*)\)/g,
      (match, parentheses1) => { // match := text that was matched in the markdown
        // return what should replace this?
        // console.log(parentheses1);
        return match.replace(parentheses1, eval(parentheses1))
      }
    );
  }
  selctors(){
    
      return Array.from(document.querySelectorAll("h3")).map(node => node.innerHTML)
  }

  render() {
    return (
      <div>
      <NavigationBar></NavigationBar>
      {/* <img src={require('/Users/tarunikaravichandran/portfolio/src/tileImages/Paasto.svg')} /> */}
      <div className="paasto-text">
      {/* {console.log(this.state.terms)} */}
      <div className='progress-container progress-responsive-position'>
      <ScrollLink activeClass="scroll-active" to="para1" spy={true} smooth={true} offset={-100} >
 			          <span className='label'>Heading</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para2" spy={true} smooth={true} offset={-100} >
 			          <span className='label'>User Study</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para3" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>User Research</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para4" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Outcomes</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para5" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Prototypes</span>
 			        </ScrollLink>
              <ScrollLink activeClass="scroll-active" to="para6" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Final Outcome</span>
 			        </ScrollLink>
 			        <a onClick={scrollToTop}>Back to Top</a>
               </div>
        <ReactMarkdown source={this.state.terms} escapeHtml={false} />
        {
          console.log(elename[0])
        }
        
        
       {/* <Element name="para1"> {this.state.terms=="Alpha prototype"} </Element> */}

      </div>
      </div>
    )
  }
}

export default Reach;