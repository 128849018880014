import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import paasto from './Paasto.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ImageStyle.css';
import ReactDOM from "react-dom";
var Scroll  = require('react-scroll');


var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class kaza extends Component {
  
  
  
  

  render() {
   
    

    return (
      <div>
      <NavigationBar></NavigationBar>
    <div className = "Intro">
     The documentation is a work in progress but here is a quick summary of the project.

     <p>
     Challenge: The challenge presented to me was to design an app that best showcases the talent of the chef and satisfies the users while adhering to design heuristics.
     </p>
     <p>
     Research: The desire for easy cooking is universal. With this in mind, I was able to identify various scenarios and customers for whom this app would be relevant. The most common customer segments being:

    <ul>Students: Students staying away from home often crave to eat delicious home cooked food. This app gives recipes for just that.</ul>
    <ul>People looking to make fancy food easily: Many people have trouble figuring out how to cook fancy dishes easily at home. This app also teaches easy home-cooking of exotic dishes.</ul>
     </p>
     <p>
     With these user groups in mind, I set out to conduct semi-structured interviews and sent out questionnaires and understood that the app had to display these recipes categorized by the time required to prepare the dish     </p>
     <p>
     DESIGN OF THE APPLICATION
     Landing page/ Home page: This is the page that is displayed as soon as the user opens the app. It shows the various categories into which the recipes are classified. From the user surveys conducted, I categorized them based on cooking time.     </p>
    <p>
    Category Page: The app navigates to this page when the ‘Starving’ category is chosen in the landing page. From this page, the user can navigate either back to the home page or to their favourite recipes.    </p>
    <p>
    Loading page: Between navigations, if the app takes time to load. The user sees this screen as the loading page    </p>
    <p>Recipe Page: 

    The user navigates to this page upon click of one of the cards in the category page.

    <ul>The user can scroll through to read.</ul>
    <ul>A video on how the recipe is made is also present at the bottom of the post.</ul>
    <ul>The user also has the ability to like, comment and share.</ul>

    </p>
    </div>
      
      </div>
    )
  }
}

export default kaza;