
import React, { Component } from 'react';
import {
  HashRouter as Router, 
  Route, Link , activeStyle, activeClassName, NavLink, withRouter
  } from 'react-router-dom';
import { IndexLink } from 'react-router'
import 'typeface-roboto';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import './Navigationbar.css'

import Headroom from 'react-headroom';

import './App.css';
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default function NavigationBar (props){
  
  
   
   
return (
  
    <Headroom >
     

    <div className = "background corners borderXwidth">
           
           <li > <NavLink exact to ='/'  activeClassName="current"  > Work  </NavLink> </li> 
            {/* <li > <NavLink exact className = "current1" activeClassName="current" to='/work' > Work  </NavLink> </li> */}
            <li >  <NavLink exact  activeClassName="current" to='/about' > About  </NavLink> </li>
            <li>  <NavLink exact activeClassName="current" to='/hobbies'  > Illustrations  </NavLink>  </li>
             {/* <a  className='text-link' id = "page2" href='#/work' activeClassName="active">Work </a> 
             <a  className='text-link' id="page3" href='#/about' activeClassName="active"> About</a> 
             <a  className='text-link' id="page4" href='#/hobbies' activeClassName="active"> Miscellaneous </a>  */}
      
       </div>   

      
       
      
		        </Headroom>
           
      
 

  );

}
//export default withRouter(NavigationBar);











  
    
      




