import Paasto from './tileImages/Paasto.png';
import Hsl from './tileImages/Hsl.svg';
import Reach from './tileImages/Reach.png';
import UIC from './tileImages/UIC.svg';
import FriendMap from './tileImages/FriendMap.png'



const cardData = [
  {
    img: Paasto,
    title: 'Paasto',
    heading:'FriendMap',
    tag1: 'User Research',
    tag2: 'UX Design',
    description:'A platform to offset your carbon emissions',
    timeRead:'8 min read',
    author: 'author',
   //  icon: figma,
 },
  {
    img: FriendMap,
    title: 'FriendMap',
    tag1: 'Web Design',
    tag2: 'UI Design',
    
    description:'A platform to keep track of your friends\' milestone locations',
    timeRead:'9 min read',
    author: 'author',
   //  icon: figma,
 },
   
  {
    img: Reach,
    title: 'Reach',
    tag1: 'Rapid prototyping',
    tag2: 'User testing ',
    description:' A Service to send and receive parcles effortlessly',
    timeRead:'8 min read',
   author: 'author',
 },
   {
    img: Hsl,
    title: 'HSL',
   
    tag1: 'Usability Evaluation',
    tag2: 'User research',
    description:'Usability study on a system that logs public tranport in Helsinki',
    timeRead:'~ WIP ~',
   author: 'author',
  },

];
export default cardData;