import React from 'react'
import NavigationBar from './NavigationBar';
// const descriptions= [];
const BlogItem = (props) => (

    <div>
        <NavigationBar></NavigationBar>
        <br/>
      <h1 className="Intro">{props.title}</h1>
      <p className="Intro">{props.text}</p>
      {/* {console.log(props.images)} */}
      {/* {
           props.images.map((image,index)=>{
            console.log(image.fields.file.url);
            return(

              <img src= {image.fields.file.url} alt="heleoeleoe" />

            );

        }

        )
      } */}
      {/* {
           this.props.explanation.map((exp,index1)=>{
            console.log(exp);
            return(
                
              <div key={index1}> {exp.fields.} </div>
              
            );
           
        }
        )
      } */}

     {/* {
         this.props.images.map((image,index)=>{
             return(
               <div key={index}> {image.type} </div>
             );
         }
         )
     }
       */}


    </div>
  )
export default BlogItem