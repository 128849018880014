import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import reach from './reach.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ReachStyles.css';
import './FriendMap/FMStyle.css'

// IMPORT IMAGES HERE
var reachTower = require('./Reach/ReachTower.png');
var Tower = require('./Reach/Tower3D.png');
var banner = require('./Reach/ReachBanner.png');
var brainwritng = require('./Reach/BrainWriting.png');
var voting = require('./Reach/Voting.png');
var cs = require('./Reach/crowdsourcing.png');
var cardboard = require('./Reach/CardboardLofi.png');
var reachIdeation = require('./Reach/ReachIdeation.png');
var process = require('./Reach/theProcess.png')
var image2 = require('./PortfolioUse.png');
var paasto = require('./Paasto.svg');
var SendItem = require('./Reach/SendItem.gif');
var FillItem = require('./Reach/FillDetails.gif');
var PaymentReach = require('./Reach/PaymentReach.gif');
var smartLocker = require('./Reach/SmartLockers.png');
var reachcard = require('./Reach/ReachTowerCard.png');
var wireframes = require('./Reach/wireframes.png');
var BMC = require('./Reach/BMC.png');
var VPC = require('./Reach/VPC.png');
var sendParcel = require('./Reach/SendParcel.png');
var measureParcel= require('./Reach/MeasuringParcel.png');
var Payment= require('./Reach/Payment.png');
var verify= require('./Reach/Confirmingpayment.png');
var success= require('./Reach/Success.png');
var dd= require('./Reach/DoubleDiamond.png');


var elename = Array.from(document.querySelectorAll("h3")).map(node => node.innerHTML);

var Scroll  = require('react-scroll');

var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class Reach extends Component {
  constructor(props) {
    super(props)

    this.state = { terms: null }
  }

  componentWillMount() {
    fetch(reach) // download the md file with HTTP GET
    .then((response) => response.text()) // get md text from the .md network response
    .then(this.mapImages) // replace the images
    .then((text) => {
      this.setState({ terms: text })
    })
  }

  mapImages(markdownText) {
    // const escapeRegex = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // create search regex
    // var regex = new RegExp( IMAGE_MAPPING.map(escapeRegex).join("|"), "g");
    // replace with image paths
    return markdownText.replace(/!\[.*\]\((?!http)(.*)\)/g,
      (match, parentheses1) => { // match := text that was matched in the markdown
        // return what should replace this?
        // console.log(parentheses1);
        return match.replace(parentheses1, eval(parentheses1))
      }
    );
  }
  selectors(){
    
      return Array.from(document.querySelectorAll("h3")).map(node => node.innerHTML)
  }

  render() {
    return (
      <div>
      <NavigationBar></NavigationBar>
      {/* <img src={require('/Users/tarunikaravichandran/portfolio/src/tileImages/Paasto.svg')} /> */}
      <div className="reach-text">
      {/* {console.log(this.state.terms)} */}
      <div className='progress-container progress-responsive-position'>
      <ScrollLink activeClass="scroll-active" to="para1" spy={true} smooth={true} offset={-100} >
 			          <span className='label'>Overview</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para2" spy={true} smooth={true} offset={-100} >
 			          <span className='label'>Process</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para3" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Research</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para4" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Ideation</span>
 			        </ScrollLink>
 			        <ScrollLink activeClass="scroll-active" to="para5" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Prototypes</span>
 			        </ScrollLink>
              <ScrollLink activeClass="scroll-active" to="para6" spy={true} smooth={true} offset={-100}>
 			          <span className='label'>Reflections</span>
 			        </ScrollLink>
 			        <a onClick={scrollToTop}>Back to Top</a>
               </div>
        <ReactMarkdown source={this.state.terms} escapeHtml={false}/>
        {
          console.log(elename)
        }
        
        
       {/* <Element name="para1"> {this.state.terms=="Alpha prototype"} </Element> */}

      </div>
       <div className = "PaastoPrev">
       <a className="newstyling" href="#/FriendMap"> ← FriendMap </a>
       <a className="newstyling" href = "#/HSL"> HSL → </a>
       </div>
      </div>
    )
  }
}

export default Reach;