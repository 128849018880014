import React from 'react';
import * as contentful from 'contentful';
import BlogItem from './BlogItem'
class fetchingData extends React.Component {  state = {
    posts: [],
    postID:[]
  } 
 client = contentful.createClient({
    space: 'fmas0e2fgvvn',
    accessToken: 'lY57mqyDOW09Z2xK3tSUP7-nbXs5-pU5qeppJUOUAKM'
  })  
  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
   // this.id().then(this.setPosts);
  }  
  
  fetchPosts = () => this.client.getEntries()  
  id = ()=> this.client.getEntry('5yIrWBcuUnaoAZOBwIYirH')
  setPosts = response => {
    this.setState({
      posts: response.items,
     // postID: response.sys
     
    })
  }  
  
//   setPostID = entry =>{
//       this.setState({
//         postID: entry.sys
//       })
//   }
  render() {
    return (
      <div>
          {console.log(this.state.postID)}
        { this.state.posts.map(({fields}, i) =>
          <BlogItem key={i} {...fields}/>
        )}
       
      </div>
    )
  }
}
export default fetchingData;
