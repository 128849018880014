import React, { Component } from 'react';
import 'typeface-roboto';
import Work from './Work.js';
import Hobbies from './Hobbies.js';
import About from './About';
import paasto from './paasto';
import reach from './Reach';
import './App.css';
import {
  HashRouter as Router,
  Route, Link
  } from 'react-router-dom';
import NavigationBar from './NavigationBar.js';
import { Switch } from '@material-ui/core';
import fetchingData from './fetchingData.js';
import Friendmap from './Friendmap.js';
import  Hsl  from './Hsl.js';
import heartFax from './heartFax';
import wearTrack from './wearTrack';
import kaza from './kaza';
import UIC from './NamibianID';
import Flyers from './postersAndFlyers';

class App extends Component {
  render(){
    return(
      <Router>
        <div>
          <Route exact path='/' component={Menu}/>
            {/* <Route exact path="/" component={App}/> */}
            {/* <Route exact path="/work" component={()=><Work/>}/> */}
            <Route exact path="/about" component={()=><About/>}/>
            <Route exact path="/hobbies" component={()=><Hobbies/>}/>
            <Route path="/paasto" component={paasto}/>
            <Route path="/HSL" component={Hsl}/>
            <Route path="/Reach" component={reach}/>
            <Route path="/FriendMap" component={Friendmap}/>
            <Route path="/HeartFax" component={heartFax}/>
            <Route path="/WearTrack" component={wearTrack}/>
            <Route path="/KazasKitchen" component={kaza}/>
            <Route path="/UIC" component={UIC}/>
            <Route path="/Flyers" component={Flyers}/>
            {/* <Link {...this.props} activeClassName="active"/> */}
            </div> 
     </Router>
    );

  }
}

function Menu(props) {
  
  return (
    <div className="noscroll">
      <Work></Work>
      {/* <NavigationBar></NavigationBar> */}
     </div>
  );
}

export default App;