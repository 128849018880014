
import React, { Component } from 'react';
import {
  HashRouter as Router, 
  Route, Link , activeStyle, activeClassName, NavLink, withRouter
  } from 'react-router-dom';
import { IndexLink } from 'react-router'
import 'typeface-roboto';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import smallMe from './TarunikaDotMe.png'

import Headroom from 'react-headroom';

import './App.css';
const useStyles = makeStyles(theme => ({
 
  list: {
    width: 350,
  },
  
 
}));



export default function Footer (props){
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
    className={classes.list}
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className="Desc">
      <img className="head-me" src ={smallMe}></img>
      <h3> Tarunika dot ME</h3>
      <p> This website was built with React , Material UI and <i> a lot of learning </i> by me. I started coding this in August 2019 and it is a work in progress.</p>
      <p> This is a space for me to share my designs and other things I find interesting. I took design inspiration from <a href="http://kaiwei.design/#/" target="_blank"> Kaiwei Wang</a> and <a href="https://medium.com/bestfolios/10-student-portfolios-done-right-2019-7d4fa0f033d" target="_blank"> Medium articles</a></p>
      {/* <p> For a detailed description of how I built this and the design decisions I made, check the project tile about this website </p> */}
      {/* <img src={}></img> */}
      </div>
    </div>
  );


     
return (
  
  
    <div className = "footer">
           
          <p onClick={toggleDrawer('right', true)}> <u>Designed and Developed by Tarunika Ravichandran ❤ </u></p>
          <Drawer anchor="right" open={state.right} onClose={toggleDrawer("right", false)} > {sideList("right")}</Drawer> 

      
       </div>   

 
  );

}
//export default withRouter(NavigationBar);











  
    
      




