import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AOS from 'aos'; 
import 'aos/dist/aos.css';
import NavigationBar from './NavigationBar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import cardData from './cardData';
import allData from './allData';
import Box from '@material-ui/core/Box';
import { createMuiTheme } from '@material-ui/core/styles';
import {withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import myAvatar from './Tarunika_Avatar_stuff.png'
import drawing from './DrawingofMe.png'
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { faReact} from "@fortawesome/free-brands-svg-icons";
import Footer from './Footer'




AOS.init({
  once: false,
  duration: 500
 });
 
const useStyles = makeStyles(theme => ({
  root:{
   shadows:'none',
   overflow: 'hidden',
   marginBottom: '-50px',
  
   
  },

  card: {
    
    // marginBottom:'-300px'
  
    // maxWidth: 345,
    // maxWidth: 300,
    // margin: 'auto'
  },
  cardsGrid: {
    maxWidth: 1200,
    marginTop: '-144px'
  },
  cardContainer: {
    maxWidth: 320,
    padding:'65px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
   
   
  },
  list: {
    width: 350,
  },
  
 
}));
const AntTabs = withStyles({
  root: {
    
    
    borderBottom: '1px solid #e8e8e8',
    // width:'100%',
    
  },
  indicator: {
    backgroundColor: '#696969',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    display:'flex',
    justifyContent: 'flex-end',
    
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(3),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Cairo',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#696969',
      opacity: 1,
    },
    '&$selected': {
      color: '#000000',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#000000',
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);



export default function FullWidthGrid() {


  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className="Desc">
      <p> I built this website with React <i className="fa fa-dribbble" aria-hidden="true"></i> and Material UI. I started coding this in August 2019 and it is a work in progress.</p>
      <p> This is a space for me to share my designs and other things I find interesting. I took design inspiration from <a href="http://kaiwei.design/#/"> Kaiwei Wang</a> and <a href=""> Medium articles</a></p>
      {/* <p> For a detailed description of how I built this and the design decisions I made, check the project tile about this website </p> */}
      {/* <img src={}></img> */}
      </div>
    </div>
  );

 return (
    <div >
    <NavigationBar></NavigationBar>
    
      <Grid container spacing={10} justify = "center" className="replaceWithFooter">
        <Grid item xs={12}  >
        <div className="landing-banner">
          <img src = {drawing} className="avatar"></img> 
          <div className = "who-am-i">
          <h3 data-aos="zoom-out" data-aos-duration="1000" >Tarunika </h3>
          <div className="seperator-small"></div> 
          <p data-aos="zoom-out" data-aos-duration="1000">A UX designer, ideating and iterating at the intersection of People, Products and Technology to drive positive change</p>
          </div>
        </div>
        {/* <div classname="lets-fix">
        <div className="landing-banner">
        <img src = {myAvatar} className="avatar"></img> 
        <h3 >Tarunika Ravichandran</h3>
        </div>
        <p>Aiming to design digital products that make the user feel that they are in control using honest design principles.</p> */}
        {/* <div className="seperator-small"></div> */}
        {/* </div> */}
        {/* <div className="Intro">
          <h3>Tarunika Ravichandran 👋🏾</h3>
          <p >  Aiming to design digital products that make the user feel that they are in control using honest design principles.
               <p>I am completing the second year of my Master's studies with a major in HCI and a minor in Innovation Management at KTH in Sweden.</p>
               <p> I am currently looking for thesis and internship positions that give me the opportunity to learn how to solve problems by approaching them from different fronts with the user at the center of the process <i>and find my mark as a designer.</i> </p>
               
              <p> During my master's program I have done many personal and school projects with  <b className="website" onClick={toggleDrawer('right', true)}><u> this website </u></b>  
              <Drawer anchor="right" open={state.right} onClose={toggleDrawer("right", false)} > {sideList("right")}</Drawer> being the most recent of them all.
               Check them out below 🙂</p> */}

               {/* <a href = {LinksWorkText}><b><u> ← website → </u></b></a> */}
         {/* </p>
          </div> */}

          {/* <div >
            <a href = "#/work/recent" > RECENT </a> ALL 
          </div> */}
        </Grid>
        <Grid item container xs={12} spacing={5} className={classes.cardsGrid} >
        <Grid item xs={12} data-aos="fade-up" > 
       {/* <div className="projectsBar"> PROJECTS</div> */}
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="Recent"/>
          <AntTab label="Archived" />
        </AntTabs>
        </Grid>
       
        {value === 0 && cardData.map(cardData=>(
          <Grid item xs={12} md={4} className="spacing">
          <div className="tile">
            <Card key = {cardData.img} data-aos="fade-up" elevation={0} > 
            
             <a href = {"#/" + cardData.title} >
            <CardMedia className = {classes.media}
                      image = {cardData.img}
                      title={cardData.title}
                      
                    />
                  <CardContent className={classes.root}>
                  <div className="card-height">
                  <div className = "title-tags" >
                     {cardData.title}
                    </div>
                  <div className = "tile-desc"  >
                      {cardData.description}
                    </div>
                    <span id="toRead">Click to read the full case study</span>
                    <div className = "tags">
                    <div className = "tile-tags" >
                    {cardData.tag1} 
                    </div>
                    <div className = "tile-tags" >
                    {cardData.tag2} 
                    </div>
                    </div>
                    <hr/>
                    <div className="time-read">
                   
                    {cardData.timeRead} 
                    </div>
                    </div>
                    
                   
                  </CardContent>
                </a>
               
            </Card>
         
           
            </div>
          </Grid>
        ))}
        {value ===1 && allData.map(allData=>(
          <Grid item xs={12} md={4}>
          <div className="tile">
            <Card key = {allData.img} data-aos="fade-up" elevation={0} > 
            
             <a href = {"#/" + allData.title} >
            <CardMedia className = {classes.media}
                      image = {allData.img}
                      title={allData.title}
                      
                    />
                   <CardContent className={classes.root}>
                  <div className="card-height">
                  <div className = "title-tags" >
                     {allData.title}
                    </div>
                  <div className = "tile-desc"  >
                      {allData.description}
                    </div>
                    <span id="toRead">Click to read the full case study</span>

                    <div className = "tags">
                    <div className = "tile-tags" >
                    {allData.tag1} 
                    </div>
                    <div className = "tile-tags" >
                    {allData.tag2} 
                    </div>
                    </div>
                    <hr/>
                    <div className="time-read">
                   
                    {allData.timeRead} 
                    </div>
                    
                    </div>
                    {/* <br/> {cardData.timeRead}  */}
                  </CardContent>
                    
                  {allData.icon}
                </a>
               
            </Card>
         
           
            </div>
          </Grid>
        ))}
        </Grid>
      </Grid>

      <Footer></Footer>
   
    </div>
  );
}

