import Flyers from './tileImages/Flyers.png';
import WearTrack from './tileImages/WearTrack.png';
import HeartFax from './tileImages/HeartFax.png';
import UIC from './tileImages/UIC.png';
import KazasKitchen from './tileImages/KazasKitchen.png'
import figma from './tileImages/Figma-logo.svg';



const allData = [
  {
    img: HeartFax,
    title: 'HeartFax',
    tag1: 'Emergent UI',
    tag2: 'Arduino ',
    author: 'author',
    description:' Sending Emotions to Friends with Facial Recognition',
    timeRead:'7 min read',
 },
  {
    img: UIC,
    title: 'UIC',
    tag1: 'Protyping',
    tag2: 'User Research ',
    author: 'author',
    description:' Mobile Application for ID application in Namibia',
    timeRead:'7 min read',
 },
 {
  img: WearTrack,
  title: 'WearTrack',
  tag1: 'NFC Tags',
  tag2: 'Ardunio',
  author: 'author',
  description:' A tag to keep track of all your belongings',
  timeRead:'7 min read',
},
{
  img: KazasKitchen,
  title: 'KazasKitchen',
  tag1: 'Android App',
  tag2: 'UI Design ',
  author: 'author',
  description:' An application that helped a local chef post and share recipies',
  timeRead:'7 min read',
},
{
  img: Flyers,
  title: 'Flyers',
  tag1: 'Poster', 
  tag2: 'Graphic Design',
  author: 'author',
  description:'Flyers and Posters Designed for a client in Australia',
  timeRead:'2 min read',
},


];
export default allData;