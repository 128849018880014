import React from 'react';

import NavigationBar from './NavigationBar';
import Grid from '@material-ui/core/Grid';
import AOS from 'aos'; 
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import illusData from './illusData';
import './Illustration.css';

import { makeStyles } from '@material-ui/core/styles';



import Footer from './Footer'
import { read } from 'fs';
const useStyles = makeStyles(theme => ({
  root:{
   shadows:'none',
   overflow: 'hidden',
  //  marginBottom: '-50px',
  
   
  },

  
  cardsGrid: {
    maxWidth: 1200,
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px'
  },
  cardContainer: {
    // maxWidth: 320,
    // padding:'65px',
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
    // paddingRight: '30%'
   
   
  },
 
 
}));

function Work(props) {
  const classes = useStyles();
    
  
  
 
    return(
      <div className="noscroll">
      
      <NavigationBar></NavigationBar>
      
      <Grid item container xs={12} spacing={5} className={classes.cardsGrid} >

      {illusData.map(illusData=>(
      <Grid item xs={12} md={4} className="spacing">
          <div className="tileIllus">
            <Card key = {illusData.img} data-aos="fade-up" elevation={0} >  
            <CardMedia className = {classes.media}
                      image = {illusData.img}/>
                  <CardContent className={classes.root}>
                  <div className="card-height">
                  <span id="descIllus">{illusData.description}</span>
                   </div>
                  </CardContent> 
            </Card>
         
           
            </div>
          </Grid>
      ))}
      </Grid>
    
       <Footer/>
       </div>
     
    );
  
  
}
export default Work;

