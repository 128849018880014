import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import paasto from './Paasto.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ImageStyle.css';
import ReactDOM from "react-dom";
var Scroll  = require('react-scroll');


var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class postersAndFlyers extends Component {
  
  
  
  

  render() {
   
    

    return (
      <div>
      <NavigationBar></NavigationBar>
    <div className = "Intro">
     The documentation is a work in progress but here is a quick summary of the project.

     <p>
     I worked freelance for a company called Ganecon IT Services and designed the marketing collateral for their clients.     </p>
     <p> the poster and work will be poster here soon</p>
    </div>
      
      </div>
    )
  }
}

export default postersAndFlyers;