import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import friendMap from './FriendMap/Friendmap.md'
import NavigationBar from './NavigationBar.js';
import './sideScroll.css';
import './FriendMap/FMStyle.css'
// import './ImageStyle.css';
import ReactDOM from "react-dom";
import { Grid } from '@material-ui/core';

var Scroll  = require('react-scroll');




var Banner = require('./FriendMap/FriendMapImages/Banner.png');
var Overview1 = require('./FriendMap/FriendMapImages/Overview1.gif');
var AddFriend = require('./FriendMap/FriendMapImages/AddFriend.gif');
var EditFriend = require('./FriendMap/FriendMapImages/EditFriend.gif');
var FilterLocations = require('./FriendMap/FriendMapImages/FilterLocations.gif');
var InformationArc = require('./FriendMap/FriendMapImages/InformationArchitechture.svg');
var UserGoal1 = require('./FriendMap/FriendMapImages/UserGoals1.svg');
var UserGoal2 = require('./FriendMap/FriendMapImages/UserGoals2.svg');
var plan = require('./FriendMap/FriendMapImages/Plan.svg');
var kanban = require('./FriendMap/FriendMapImages/Kanban.png');
var userTest = require('./FriendMap/FriendMapImages/UserTestFM.jpeg');
var problems1 = require('./FriendMap/FriendMapImages/OldScreen.png');
var problems2 = require('./FriendMap/FriendMapImages/Problems2.png');
var NewScreens = require('./FriendMap/FriendMapImages/NewScrens.png');

var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;

const headings = ["Overview", "Understanding", "Information Architechture", "User Goals", "Design", "MVP", "Reflections"];

function scrollToTop() {
     scroll.scrollToTop();
   }

class Friendmap extends Component {
  constructor(props) {
    super(props)

    this.state = { terms: null }
  }

  componentWillMount() {
    fetch(friendMap) // download the md file with HTTP GET
    .then((response) => response.text()) // get md text from the .md network response
    .then(this.mapImages) // replace the images
    
    .then((text) => {
      this.setState({ terms: text })
    })
    
  }
  

  mapImages(markdownText) {
    // const escapeRegex = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // create search regex
    // var regex = new RegExp( IMAGE_MAPPING.map(escapeRegex).join("|"), "g");
    // replace with image paths
    return markdownText.replace(/!\[.*\]\((?!http)(.*)\)/g,
      (match, parentheses1) => { // match := text that was matched in the markdown
        // return what should replace this?
      //  console.log(parentheses1);
        return match.replace(parentheses1, eval(parentheses1))
      }
    );
  }

  renderHeader(props) {
    
    const is3 = level => level === 3 ? "-" : "";
    const h3Wrap = (level, heading) => <>{is3(level)} {heading} {is3(level)}</>

    const innerHeading = React.createElement(`h${props.level}`, {class: "thisisours"}, h3Wrap(props.level, props.children));
    
    
    const getName = heading => {
      let index = headings.indexOf(heading);
      if (index !== -1) { // it was found
        return "para" + (index + 1); 
        
      } else return -1; // was not found
    }
  
    const name = getName(props.children[0].props.value);
    if (name === -1) {
      return innerHeading;
    } else {
      return <Element name={name}>{innerHeading}</Element>
    }
  }
  

  render() {
    // const node = ReactDOM.findDOMNode(this);
    return (
      <div>

      <NavigationBar></NavigationBar>
     
      
      <img className='friendMapBanner' src={require('./FriendMap/FriendMapImages/Banner.svg')}/>
      
  

      <div className="FriendMap">       
      <div className="progress-container progress-responsive-position">
      {
              headings.map((heading, index) => 
                (<ScrollLink activeClass="scroll-active" to={"para" + (index + 1)} spy={true} smooth={true} offset={-30}>
                  <span className='label'>{heading}</span> 
                </ScrollLink>))

               
              }
              {/* {console.log(node.querySelectorAll("h3"))} */}
              
               {/* {
                Array.from(document.querySelectorAll("h3")).map(
                node => node.innerHTML).forEach(function(ele){
                  console.log(ele);
                })
              
              }  */}
 			        <a onClick={scrollToTop}>Back to Top</a>

             
               


               </div>
               
        
         <ReactMarkdown source={this.state.terms}
         escapeHtml={false}
         renderers={{"heading": this.renderHeader.bind(this) }}
          /> 
          
          
       

      </div>
      <div className = "PaastoPrev">
      <a className="newstyling" href="#/paasto"> ← Paasto </a>
      <a className="newstyling" href = "#/Reach"> Reach → </a>
      </div>
      </div>
    )
  }
}

export default Friendmap;


