import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import paasto from './Paasto.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ImageStyle.css';
import ReactDOM from "react-dom";
var Scroll  = require('react-scroll');


var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class wearTrack extends Component {
  
  
  
  

  render() {
   
    

    return (
      <div>
      <NavigationBar></NavigationBar>
    <div className = "Intro">
     The documentation is a work in progress but here is a quick summary of the project.

     <p>
     The aim of this project was to make people’s lives easier by making sure people do not lose their valuables or not forget them when they leave a place. I worked on this project with 2 other teammates from my class and we implemented this using Near Field Communication mechanism. We programmed a raspberry pi using python, to keep track of the RFID tags attached to the items of interest to which NFC stickers were stuck. Each of these items was thus identified with a unique NFC id.     </p>
     <p>
     These NFC stickers are connected virtually using an android mobile application which had been developed exclusively for this purpose. The application was programmed to trigger an alarm in case one of the virtually connected devices left the network.     </p>
     <p>
     What I learnt from this project?     </p>
     <p>
     This was an interesting project for me to work on as this was my first practical/firsthand experience of human-computer/technology interaction. I learnt how to use a Rasberry Pi.  This project was also the first time I learnt Python. Although this project delivered what it intended it did not have the perfect UI and this was my starting point to learning about user-centred design methods.     </p>
    
    </div>
      
      </div>
    )
  }
}

export default wearTrack;