import React from 'react';
import Grid from '@material-ui/core/Grid';
import image from './AboutMePhoto.png';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReact} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import NavigationBar from './NavigationBar';
import Hobbies from './Hobbies'
import './about.css';
import dog from './paintingImages/DkPainting.jpeg';
import landscape from './paintingImages/AnanyaPainting.jpeg';
import tower from './paintingImages/effielPainting.jpeg';
import tree from './paintingImages/PinkyPainting.jpeg';
import rb from './paintingImages/RbPainting.jpeg';
import dance from './paintingImages/ShivaniPainting.jpeg';
import ganesha from './paintingImages/AkkaPainting.jpg';
import warli from './paintingImages/Warli.png';


import Footer from './Footer'






const useStyles = makeStyles(theme => ({
    picture: {
        // padding: theme.spacing(2),
        // position: 'absolute',
        // top:"10%",
        // right:'15%',
        // left: "130%",
        // top: "-10%",
        maxHeight:'300px',
        maxWidth:'300px',
        // width:'50%',
        borderRadius:'800px',
        marginTop:'5em',
       
       
        
       
        
       
      },
    }));


function Work(props) {
    const classes = useStyles();
  
  
 
    return(
      <div className="noscroll">
      
      <NavigationBar></NavigationBar>
      {/* <svg width="2248px" height="315px" viewBox="0 10 1633 315" className="waves" > */}
      <svg width="2248px" height="300px" viewBox="0 0 2248 283"  className="waves" >

      <g id="Waves" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect fill="#FFFFFF" x="0" y="0" width="2248" height="283"></rect>
        <path d="M1442.29604,8.63149422 C2364.88901,6.87716474 2789.79634,6 2717.01803,6 C2600.4572,6 2758.64186,116.926857 2431.65599,122.08852 C2104.67013,127.250183 2178.68364,30.9841189 2033.6957,30.9841189 C1888.70776,30.9841189 1899.2861,138 1722.2208,138 C1545.1555,138 1515.68793,52.2720436 1420.88173,30.9841189 C1419.51463,30.6771485 1418.15014,30.3733894 1416.78826,30.0728417 C1402.69785,63.0072504 1411.60801,118.474378 1182.65599,122.08852 C855.670126,127.250183 929.683644,30.9841189 784.695701,30.9841189 C639.707758,30.9841189 650.286105,138 473.220803,138 C296.155501,138 246.687928,52.2720436 151.881728,30.9841189 C88.6775949,16.792169 37.7170189,9.46412942 -1,9 C1056.05257,7 1545.72525,6 1468.01803,6 C1457.32884,6 1448.9502,6.9328697 1442.29604,8.63149257 Z" id="EndWave" fill="#F8F7F1"></path>
        <path d="M1001.76127,-5.93634874 L1001,-17 C2170.64777,-19 2731.72084,-20 2684.21924,-20 C2605.42612,-20 2622.99766,10.6590719 2531.04993,44 C2493.46928,57.627021 2441.33405,91.5141509 2350.99242,92.9323103 C2039.61396,97.8202487 2110.09473,6.65920347 1972.02722,6.65920347 C1833.95971,6.65920347 1844.03314,108 1675.41937,108 C1561.35745,108 1502.89847,70.8504305 1449.06374,40.5719255 C1438.8285,45.8842268 1426.48165,51.4043429 1411.04993,57 C1373.46928,70.627021 1321.33405,104.514151 1230.99242,105.93231 C919.613957,110.820249 990.094727,19.6592035 852.027221,19.6592035 C713.959714,19.6592035 724.033144,121 555.419368,121 C386.805591,121 339.69916,39.8182231 249.418166,19.6592035 C189.230837,6.21985705 66.9674292,6.21985705 -117.372056,19.6592035 L-119,-4 C354.191101,-4.80911727 727.77819,-5.45456684 1001.76127,-5.93634874 Z" id="MiddleWave" fill="#FFFCDA"></path>
        <path d="M1440.50549,19.2314324 C1407.9576,42.0398353 1385.01523,81.1190609 1174.3631,83.8694057 C875.615534,87.769954 943.542985,3 811.076113,3 C678.609241,3 688.274048,83.8694057 526.5,83.8694057 C364.72595,83.8694057 312.751799,37.4750435 226.133005,21.3882552 C168.387142,10.6637297 53.3428074,4.53431127 -119,3 C527.636344,3 981.404347,3 1242.30401,3 C1198.05346,1.50405845 1147.61879,0.504058452 1091,0 C2241.87123,0 2781.80861,0 2710.81214,0 C2604.31743,0 2683.11066,76.9688574 2384.3631,80.8694057 C2085.61553,84.769954 2153.54299,0 2021.07611,0 C1888.60924,0 1898.27405,80.8694057 1736.5,80.8694057 C1577.46618,80.8694057 1524.54404,36.0334428 1440.50549,19.2314324 Z" id="FontWave" fill="#FFF79E"></path>
    </g>
      {/* <g id="Waves" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect fill="#FFFFFF" x="0" y="0" width="1633" height="303"></rect>
          <path d="M-228.290479,57.3499092 C-34.713616,24.7729335 93.6764942,24.7729335 156.879852,57.3499092 C251.684888,106.215373 301.151854,303 478.214982,303 C655.278111,303 644.699894,57.3499092 789.686058,57.3499092 C934.672221,57.3499092 860.659611,278.324283 1187.64146,266.47592 C1514.62331,254.627558 1428.04884,57.3499092 1544.60824,57.3499092 C1661.16764,57.3499092 1631.66841,197.325147 1631.66841,156.421147 C1631.66841,129.151814 1631.66841,77.2304343 1631.66841,0.65700796 L-230,0 L-228.290479,57.3499092 Z" id="EndWave" fill="#F8F7F1"></path>
          <path d="M-116.372059,58.9429839 C67.9670874,28.3012741 190.23027,28.3012741 250.417489,58.9429839 C340.698317,104.905549 387.804661,290 556.418128,290 C725.031595,290 714.958183,58.9429839 853.025436,58.9429839 C991.092689,58.9429839 920.612048,266.790167 1231.98994,255.645668 C1543.36783,244.501168 1460.9248,58.9429839 1571.92184,58.9429839 C1682.91889,58.9429839 1654.8274,190.602861 1654.8274,152.128802 C1654.8274,126.479429 1654.8274,77.6424877 1654.8274,5.61797778 L-118,5 L-116.372059,58.9429839 Z" id="MiddleWave" fill="#FFFCDA"></path>
          <path d="M-116.393142,48.722208 C65.5587298,23.8862958 186.238549,23.8862958 245.646316,48.722208 C334.757967,85.9760763 381.254263,236 547.68411,236 C714.113956,236 704.170999,48.722208 840.450219,48.722208 C976.729439,48.722208 907.161554,217.18782 1214.50696,208.154909 C1521.85237,199.121999 1440.47701,48.722208 1550.03659,48.722208 C1659.59618,48.722208 1631.86849,155.436003 1631.86849,124.251766 C1631.86849,103.462274 1631.86849,63.8786479 1631.86849,5.50088726 L-118,5 L-116.393142,48.722208 Z" id="FontWave" fill="#FFF79E"></path>
        </g> */}
      </svg>
      <div classname="for-contact" data-aos="fade-up">
      <div className="about-flex">
      
        <div item xs = {12} md = {6} className="Intro-About">
       
        <div className="myName">Tarunika Ravichandran 👋🏾</div>
        I am a designer with a background in tech. I am passionate about creating meaningful user-focused designs. 
       <p> I believe that designers can bring about a positive change in this world and I enjoy working towards that. With my designs, I want to contribute to building technologies that value the users’ efforts and experiences; while understanding business goals and acknowledging technical constraints.
       </p>
       <p> Currently, I am in the second year my of masters program in HCI at KTH Royal Institute of Technology in Sweden.</p>

         {/* <i>(More recently, I have developed a deep interest in value-sensitive design and its applications in the industry)</i> */}
         <p> When I am not nerding-out, I play basketball or go on bike rides with my friends </p>
        
         </div>
     
       <div item md = {6} >
         <Box display={{ xs: 'none', sm: 'none', md: 'block' }}><img src = {image} alt="myself" className= {classes.picture}  /></Box>

       </div>
      </div>
      <div>
      <div className="PaintsPlacement">  <div className="paints">and... <span className="largerpaints">I also like to Paint</span></div> </div>
        <div className="outerGrid">
          <div data-aos="fade-up">
          <img src = {tree} alt="myself" className= "imagesAbout"  />
          <img src = {rb} alt="myself" className= "imagesAbout"  />
          <img src = {dance} alt="myself" className= "imagesAbout"  />
          </div>
          <div data-aos="fade-up">
          <img src = {dog} alt="myself" className = "imagesAbout"  />
          <img src = {tower} alt="myself" className= "imagesAbout"  />
          <img src = {landscape} alt="myself" className= "imagesAbout"  />
          <img src = {ganesha} alt="myself" className= "imagesGanesha"  />
          </div>
         
        </div>
        <div>
        <div className="Contacts" data-aos="fade-up">  <div className="paints"><span className="largerpaints">Get In Touch</span></div> </div>
        <div className="ContactsLinks" data-aos="fade-up">
        <a  href="https://www.linkedin.com/in/tarunika-ravichandran-337a53119" target="_blank" >LinkedIn </a>
        <a  href="https://www.behance.net/tarunikravicha" target="_blank"> Behance </a>
        <a  href = "mailto:tarunika.ravichandran@gmail.com" target="_blank">Email </a>
        <a  href = "https://github.com/Tarunika" target="_blank">GitHub </a>
        </div>
        </div>
        <div className="lastPara" data-aos="fade-up">
          <div>Oh and.... I built this website too 😄</div>
          <div>This website by itself been a long, challenging and rewarding web-design project. Although this is still under construction I am enjoying it, every step of the way. </div>
          <div> I developed this with React JS and Material UI components. For the design, I drew inspiration from <a href="http://kaiwei.design/#/" target="_blank"> <u>Kaiwei Wang</u></a> and <a href="https://medium.com/bestfolios/10-student-portfolios-done-right-2019-7d4fa0f033d" target="_blank"> <u>Medium articles </u></a>.</div>
        </div>
      </div>
     
     
       </div>
       <Footer/>
      </div>
     
    );
  
  
}
export default Work;

