import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import paasto from './Paasto.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ImageStyle.css';
import ReactDOM from "react-dom";
var Scroll  = require('react-scroll');


var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class HeartFax extends Component {
  
  
  
  

  render() {
   
    

    return (
      <div>
      <NavigationBar></NavigationBar>
    <div className = "Intro">
     The documentation is a work in progress but here is a quick summary of the project.

     <p>
     Communication has long been a text based phenomenon, and with the introduction of emojis and more recently ap- plications like Snapchat and Instagram, sharing visual rep- resentations of the emotional state have become common place. In this project, our goal was explore how we could enhance these digital, emotional communication channels with real-time information from sensors like pulse sensors.     </p>
     
     <p>
     Many studies have explored how emotions are linked to heart rate. While this method has limitations, we overcame them by using facial expression from a webcam. The fol- lowing sections provide a design overview of the prototype with a typical use case, the research development journey that lay at the basis of it and ends with a discussion of its implications. 
     </p>
     <p>
     A typical use
     case ofthe HeartFax concept consists of
         a user attaching a heartbeat sensor to themselves and
     launching the web application
     while looking into the web
     camera on their laptop.A unique artistic visualisation is
     generated and continuously updated based on the emotion
     detected from their facial expression and BPM(heart beats per minute).When the user wants to share this visualisation at any point, they can use the ShareMyFeelz button,
         which copies a link to their clipboard.This link can be then
     sent in any way they like,
         for example by using a messaging
     application and sending it to their friend.Their friend can
     then click on the link and open the same visualisation on
     our web server.
     HeartFax is a prototype created by combining the Arduino
     Uno, a heartbeat sensor called PulseSensor and Emotion
         (Petercunha), a Python library that applies computer vision
     and machine learning algorithms to a webcam feed, to determine the the facial expressions of people in view.The
     PulseSensor and Webcam were in our protototype  </p>
    </div>
      
      </div>
    )
  }
}

export default HeartFax;