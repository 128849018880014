import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown/with-html'
import paasto from './Paasto.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ImageStyle.css';
import ReactDOM from "react-dom";
import { element } from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';



var Scroll  = require('react-scroll');
var PaastoLogo = require('./PaastoImages/Paasto_Logo.svg');
var screen1 = require('./PaastoImages/Screen1.png');
var screen2 = require('./PaastoImages/Screen2.png');
var modal = require('./PaastoImages/Modal.png');
var screen3 = require('./PaastoImages/Paasto.gif');
var PaastoBrainstorm = require('./PaastoImages/paastoBrainstorm.jpeg');
var persona1 = require('./PaastoImages/Persona1.png');
var persona2 = require('./PaastoImages/persona2.png');
var persona3 = require('./PaastoImages/persona3.png');
var userJourney = require('./PaastoImages/UserJourney.png');
var banner = require('./PaastoImages/PaastoLogo.png');
var Interview = require('./PaastoImages/Interviews.png');
var subExpa = require('./PaastoImages/subExp1.png');
var subExpb = require('./PaastoImages/subExp2.png');
var Exp2 = require('./PaastoImages/Exp2.png');
var results = require('./PaastoImages/Results.png');
var comp = require('./PaastoImages/Competetor.png');
var pen = require('./PaastoImages/penAndPaper.jpeg');
var userGoal = require('./PaastoImages/UserGoals.png');
var meduimFi = require('./PaastoImages/MediumFi.png');
var team = require('./PaastoImages/TeamPaasto.png');

// var elename = Array.from(this.refs.querySelectorAll("h3")).map(node => node.innerHTML);


// var KLMScreenshot = require('./PaastoImages/Screen1.svg');


var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

  //  const useStyles = makeStyles(theme => ({
  //   root: {
  //     width: '100%',
  //     '& > * + *': {
  //       marginTop: theme.spacing(2),
  //     },
  //   },
  // }));

class Paasto extends Component {
  
  constructor(props) {
    super(props)

    this.state = { terms: null }
    

  }

  componentWillMount() {
    
    fetch(paasto) // download the md file with HTTP GET
    .then((response) => response.text()) // get md text from the .md network response
    .then(this.mapImages) // replace the images
    
    .then((text) => {
      this.setState({ terms: text })
    })
    
    
  }
  

  mapImages(markdownText) {
    // const escapeRegex = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // create search regex
    // var regex = new RegExp( IMAGE_MAPPING.map(escapeRegex).join("|"), "g");
    // replace with image paths
    return markdownText.replace(/!\[.*\]\((?!http)(.*)\)/g,
      (match, parentheses1) => { // match := text that was matched in the markdown
        // return what should replace this?
      //  console.log(parentheses1);
        return match.replace(parentheses1, eval(parentheses1))
      }
    );
  }
  componentDidMount(){
    const node = ReactDOM.findDOMNode(this);
    console.log(Array.from(node.getElementsByTagName("h3")));
    // Array.from(node.getElementsByTagName("h3"), element => element.tagName)
   
    

  }
  
  
  

  render() {
    // const node = ReactDOM.findDOMNode(this);
    // console.log(node);
    // if(node instanceof HTMLElement){

    //  const child = node.getElementsByTagName("h3");
    //  const arraysofh3s = Array.prototype.forEach.call(child,function(){
    //    return {child}
    //  })
    //  console.log(arraysofh3s);
    
      // const child = [].slice.call(node.getElementsByTagName("h3")).map((data)=>{
      //   return(
      //     {data}
      //   )
      // })
      // console.log(child);
      // const child = node.getElementsByTagName("h3");
      // Array.from(child).map((scrolltothis) => ({scrolltothis}))
      // console.log(child);
    // }
    
    

    return (
      
      <div>
      <NavigationBar></NavigationBar>
      {/* <div className={classes.root}> */}
      {/* <LinearProgress /> */}
      {/* </div> */}
      <div className="paasto-text">
     
                
      <div className='progress-container progress-responsive-position'>
      {
              [
                // {id: "para2", heading: "The problem"},
                // {id: "para3", heading: "Understanding"}
                "Overview", "Understanding", "Research", "Personas", "User Journeys", "Design", "Evaluation", "Reflections"
                ].map((heading, index) => 
                (<ScrollLink activeClass = "scroll-active" to={"para" + (index + 1)} spy={true} smooth={true} offset={-30}>
                  <span className='label'>{heading}</span>
                </ScrollLink>))

               
              }
              {/* {console.log(document.querySelectorAll("h3"))} */}
              {/* {console.log(node.querySelectorAll("h3"))} */}
              
               {/* {
                Array.from(document.querySelectorAll("h3")).map(
                node => node.innerHTML).forEach(function(ele){
                  console.log(ele);
                })
              
              }  */}
 			        <a onClick={scrollToTop}>Back to Top</a>

             {/* {console.log(node.querySelectorAll("h3"))} */}
               


               </div>
               
        
         <ReactMarkdown source={this.state.terms}
          escapeHtml={false}
         
         /> 
         {/* {console.log(this.selectingH3s())} */}
          {/* {console.log(elename)} */}
       

      </div>
      <div className = "PaastoPrev">
      <a className="newstyling" href="#/"> ← Home </a>
      <a className="newstyling" href = "#/FriendMap"> FriendMap → </a>
      </div>
      </div>
    )
  }
}

export default Paasto;