import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import paasto from './Paasto.md'
import NavigationBar from './NavigationBar';
import './sideScroll.css';
import './ImageStyle.css';
import ReactDOM from "react-dom";
var Scroll  = require('react-scroll');


var ScrollLink = Scroll.Link;
var Element    = Scroll.Element;
var Events     = Scroll.Events;
var scroll     = Scroll.animateScroll;
var scrollSpy  = Scroll.scrollSpy;
function scrollToTop() {
     scroll.scrollToTop();
   }

class NamibianID extends Component {
  
  
  
  

  render() {
   
    

    return (
      <div>
      <NavigationBar></NavigationBar>
    <div className = "Intro">
     The documentation is a work in progress but here is a quick summary of the project.

     <p>
     In Namibia, citizens or permanent residence holders of 16 years or older can currently apply in person for an ID card at any of the 65 offices of the Ministry of Home Affairs and Immigration (MHAI). To make this process feasible and easily available, I worked in a team of 4 to design and prototype a mobile application for the same. The challenge here was to design an application that was aimed at novice users, as most citizens of Namibia do not use a mobile phone.
     </p>
     <p>
     This was designed using Sketch and the prototype was developed with Axure.
     </p>
     <p>
     The starting point was the Sketch prototype which was a screen by screen prototype with minimal interactivity. It was based on the Android app design as this application is for use with a smartphone. The initial design was screens at each stage of the application using a standard colour scheme of light blue with black and white used in conjunction with “Back” and “Next” buttons to signify moving forward in the process.
     </p>
     <p>
     Axure was the software used to generate the final prototype. Axure has the capabilities to allow for input collection, setting variable values that facilitates the flow of information through walkthroughs as well as adding conditions within the prototype to help it mimic a real app. This helped provide with the tools to implement interaction within the prototype. A combination of interaction styles was used in the app – namely menu selection and form-filling.
     </p>
    <p>
    In terms of design changes, a colour-palette using the Namibian flag as the base colours were implemented. Due to this, the app bar, back and next buttons were dark blue with other items in the app following shades of the other colours to be relevant to the country. Icons such as the green tick were used to indicate that a process was successful and general text status updates (that are timed in the prototype to mimic the real world) to indicate the ongoing fingerprint reading process.
    </p>
    <p>
    The culmination of our user research and development can be seen in our Axure prototype, which can be interacted with <a href= "https://5x9u2u.axshare.com/#g=1&p=welcome."> here: </a>
    </p>
    </div>
      
      </div>
    )
  }
}

export default NamibianID;